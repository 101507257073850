

.homogenous{
    width: 100vw;
    height: 60vh;
    display: flex;
    flex-direction: row;
}

.combination {
    max-width: 60vw;
    font-family: inherit;
    font-size: 6vh;
    font-weight: 500;
    padding: 0 15vw ;
    line-height: 7vh;
    text-align: left;
}

.combination-orange{
    color: #F58E37;
    font-family: inherit;
    font-size: 6vh;
    font-weight: 700;
}