@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');



* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

a {
  color: #000000;
}
a:visited {
  color: #000000;
}

body {
  margin: 0;
  position: absolute;
  /* width: 100vw; */
  font-family: 'Poppins', sans-serif;
}

/* @media (max-width:760px) {
  body{
    overflow-x: hidden !important;
    width: 100%;
  }  
} */
