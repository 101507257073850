@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');


.header {
    background-color: #ffffff;
    width: 100vw;
    top: 0;
    left: 0;
    height: 9vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    /* margin: 2vh 0; */
    position: fixed;
    box-shadow: 0px 0px 9px #000000E3;
    z-index: 1;
}

.logo {
    font-family: 'Poppins', sans-serif;
    padding: 2vh 0 1vh 0;
    margin: 0;
    margin-left: 2vw;
    /* max-height: 4vh; */
}

.logo:hover {
    cursor: pointer;
}

.links {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    display: flex;
    flex-direction: row;
}

.link {
    margin: 1vw;
    min-width: 8vw;
    text-decoration: none;
    transition: 0.5s;
}

.link:hover {
    cursor: pointer;
    margin-top: 0.2vw;
    color: #00C680;
}

.selected {
    list-style-type: none;
    width: 10vw;
    z-index: 2;
    position: absolute;
    right: 12vw;
    top: 4vh;
}

.each-title {
    color: black;
    background-color: #7ce7cc;
    width: 10vw;
    height: 5vh;
    padding: 1vh 0;
}

.each-title:hover {
    background-color: white;
    cursor: pointer;
    color: #2ec680;
    transition: 0.5s;
}

@media (max-width: 767px) {

    .header {
        background-color: #ffffff;
        width: 100vw;
        top: 0;
        left: 0;
        display: flex;
        height: 8vh;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* text-align: center; */
        padding: 2vh 0;
        position: fixed;
        box-shadow: 0px 0px 9px #000000E3;
        z-index: 1;
    }
    
    .links {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 10px;
    }

    .selected {
        list-style-type: none;
        width: 20vw;
        z-index: 2;
        position: absolute;
        right: 25vw;
        top: 4vh;
    }

    .each-title {
        color: black;
        background-color: #7ce7cc;
        width: 25vw;
        height: 5vh;
        padding: 1vh 0;
    }
    
    .each-title:hover {
        background-color: white;
        cursor: pointer;
        color: #2ec680;
        transition: 0.5s;
    }
}