@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.intuitive {
    width: 100vw;
    height: 97vh;
    background-color: #00C680;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.health-para {
    font-family: inherit;
    font-weight: 500;
    font-size: 5vh;
    color: #ffffff;
    max-width: 51vw;
    margin: 0 10vw 0 2vw;
    padding: 0 3vw 0 5vw;
    line-height: 1vh;
}

.accuracy {
    font-family: inherit;
    font-weight: 700;
    font-size: 10vh;
    color: #ffffff;
    /* max-width: 50vw;
    margin: 0 0 0 2vw;
    padding: 0 3vw 0 5vw; */
    line-height: 11vh;
}

.circle {
    width: 30vw;
    height: 30vw;
    /* background-color: #000000; */
    border-radius: 50%;
}

.circle > img {
    max-height: 100%;
    max-width: 100%;
}

.monitoring-uses {
    width: 100vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    margin: 10vh 0;
    align-items: center;
}

.heading {
    font-family: inherit;
    font-weight: 700;
    font-size: 6vh;
    /* margin: 8vh 0 0 0; */
}

.para {
    font-family: inherit;
    font-weight: 300;
    font-size: 2.3vh;
    color: #00C680;
    margin: 0 0 8vh 0;
}

.use-cases {
    display: flex;
    flex-direction: row;
    /* justify-content: center;
    align-items: center; */
    padding: 0 5vw;
}

.use-case {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 30vw;
    padding: 5vw;
}

.usecase-circle {
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    /* background-color: #000000; */
}

.usecase-circle > img {
    max-width: 100%;
    max-height: 100%;
}

.usecase-heading {
    font-family: inherit;
    font-weight: 700;
    font-size: 4vh;
    padding: 4vh 0;
    color: #00C680;
}

.usecase-para {
    font-family: inherit;
    font-weight: 300;
    font-size: 3vh;
    text-align: center;
}

.kyc-headers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
    height: 40vh;
    padding: 5vh 10vw 0 10vw;
}

.kyc-header {
    font-family: inherit;
    font-weight: 700;
    font-size: 8vh;
    color: #00C680;
    text-align: center;
    line-height: 8vh;
}

.kyc-para {
    font-family: inherit;
    font-weight: 300;
    font-size: 3vh;
    padding: 10vh 0 5vh 0;
}

.kyc-button {
    font-family: 'Bebas Neue';
    background-color: #00C680;
    font-weight: 400;
    font-size: 5vh;
    text-align: center;
    border: 1px solid #00C680;
    color: #FFFFFF;
    width: 15vw;
    /* height: 11vh; */
    border-radius: 33px;
    padding: 3vh;
    margin: 5vh 0 2vh 0;
    text-decoration: none;
}

.kyc-button:hover {
    color: #00C680;
    background-color: white;
}

.kyc-button:visited {
    font-family: 'Bebas Neue';
    background-color: #00C680;
    font-weight: 400;
    font-size: 5vh;
    text-align: center;
    border: 1px solid #00C680;
    color: #FFFFFF;
    width: 15vw;
    padding: 3vh;
    /* height: 11vh; */
    border-radius: 33px;
    text-decoration: none;
}

.kyc-button:visited:hover {
    color: #00C680;
    background-color: white;
}

.comparing-div{
    height: 70vh;
    display: flex;
    flex-direction: row;
}

.comparing-para {
    max-width: 55vw;
    font-family: inherit;
    font-size: 3vh;
    line-height: 4vh;
    font-weight: 300;
    padding: 10vh 0;
    /* text-align: center; */
}

.comparing-line {
    padding: 2vh 10vw;
}

.comparing-image {
    width: 30vw;
}


@media (max-width: 767px) {
    .intuitive {
        width: 100vw;
        height: 80vh;
        background-color: #00C680;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .health-para {
        font-family: inherit;
        font-weight: 400;
        font-size: 5vh;
        color: #ffffff;
        max-width: 100vw;
        margin: 0 0 5vh 0;
        /* padding: 0 3vw 0 5vw; */
        line-height: 8vh;
    }
    
    .circle {
        width: 50vw;
        height: 50vw;
        /* background-color: #000000; */
        border-radius: 50%;
    }
    
    .circle > img {
        max-height: 100%;
        max-width: 100%;
    }
    
    .monitoring-uses {
        width: 100vw;
        height: 200vh;
        display: flex;
        flex-direction: column;
        /* margin: 10vh 0; */
        align-items: center;
    }
    
    .heading {
        font-family: inherit;
        font-weight: 700;
        font-size: 6vh;
        margin: 8vh 0 0 5vw;
    }
    
    .para {
        font-family: inherit;
        font-weight: 300;
        font-size: 2.3vh;
        color: #00C680;
        margin: 0 0 8vh 5vw;
    }
    
    .use-cases {
        display: flex;
        flex-direction: column;
        /* height: 150vh; */
        /* justify-content: center;
        align-items: center; */
        padding: 0 5vw;
    }
    
    .use-case {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100vw;
        /* padding: 5vw; */
    }
    
    .usecase-circle {
        width: 50vw;
        height: 50vw;
        border-radius: 50%;
        /* background-color: #000000; */
    }
    
    .usecase-circle > img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .usecase-heading {
        font-family: inherit;
        font-weight: 700;
        font-size: 4vh;
        /* padding: 4vh 0; */
        color: #00C680;
    }
    
    .usecase-para {
        font-family: inherit;
        font-weight: 300;
        font-size: 3vh;
        text-align: center;
    }
    
    .kyc-headers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        height: 100vh;
        padding: 0 5vw;
    }
    
    .kyc-header {
        font-family: inherit;
        font-weight: 700;
        font-size: 6vh;
        color: #00C680;
        text-align: center;
        line-height: 8vh;
    }
    
    .kyc-para {
        font-family: inherit;
        font-weight: 300;
        font-size: 3vh;
        padding: 10vh 0 5vh 0;
    }
    
    .kyc-button {
        font-family: 'Bebas Neue';
        background-color: #00C680;
        font-weight: 400;
        font-size: 5vh;
        text-align: center;
        border: 1px solid #00C680;
        color: #FFFFFF;
        width: 60vw;
        /* height: 11vh; */
        border-radius: 33px;
        padding: 3vh;
        text-decoration: none;
    }
    
    .kyc-button:hover {
        color: #00C680;
        background-color: white;
    }
    
    .kyc-button:visited {
        font-family: 'Bebas Neue';
        background-color: #00C680;
        font-weight: 400;
        font-size: 5vh;
        text-align: center;
        border: 1px solid #00C680;
        color: #FFFFFF;
        width: 15vw;
        padding: 3vh;
        /* height: 11vh; */
        border-radius: 33px;
        text-decoration: none;
    }
    
    .kyc-button:visited:hover {
        color: #00C680;
        background-color: white;
    }
}
