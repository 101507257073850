.what {
    font-family: inherit;
    font-size: 6vh;
    font-weight: 700;
    padding: 5vh 25vw 0 25vw;
    line-height: 7vh;
    text-align: center;
}

.bio-medical {
    font-family: inherit;
    font-size: 2.5vh;
    font-weight: 300;
    margin: 2vh 24vw 10vh 24vw;
    text-align: center;
}

.solution {
    font-family: inherit;
    font-size: 6vh;
    font-weight: 700;
    padding: 0 25vw 0 25vw;
    line-height: 7vh;
    text-align: center;
}

.each-solution {
    display: flex;
    flex-direction: row;
    padding: 3vh 1vw;
}

.solution-title {
    color: #1C3C67;
    font-family: inherit;
    font-size: 3vh;
    font-weight: 500;
    /* margin: 2vh 24vw 10vh 24vw; */
    text-align: left;

}

.solution-para {
    color: #1C3C67;
    font-family: inherit;
    font-size: 2.5vh;
    font-weight: 300;
    /* margin: 2vh 24vw 10vh 24vw; */
    text-align: left;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    padding: 5vh 10vw;
}

.each-solution:not(:nth-child(3n)):last-child{
    grid-column: 2 / 3;
    grid-row: 3 / 3;
}

/* .each-solution:not(:nth-child(3n)) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  } */
/* 
.solution-image {
    ;
} */