.products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10vh 0;
}

.what-heading {
    font-family: inherit;
    font-size: 8vh;
    font-weight: 700;
}

.enable-para {
    font-family: inherit;
    font-size: 3vh;
    font-weight: 300;
    margin: 2vh 24vw;
    text-align: center;
}

.product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 6vh 10vw;
}


.headings {
    max-width: 55vw;
}

.green-heading {
    font-family: inherit;
    font-size: 8vh;
    font-weight: 700;
    color: #00C680;
}

.normal-heading {
    font-family: inherit;
    font-size: 8vh;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 4.5rem;
    margin: 0;
    padding: 0;
}

.para {
    font-family: inherit;
    font-size: 3vh;
    font-weight: 300;
    margin: 5vh 13vw 0 0;
    text-align: left;
    line-height: 4vh;
}

.rectangle {
    min-width: 30vw;
    width: 493px;
    height: 364px;
    /* background-color: black; */
}

.rectangle > img {
    max-width: 100%;
    max-height: 100%;
}

.big-green {
    width: 3vw;
    height: 3vw;
    background-color: #00C680;
    border-radius: 50%;
}


@media (max-width: 767px) {
    .products {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5vh 0;
    }
    
    .what-heading {
        font-family: inherit;
        max-width: 100vw;
        font-size: 5vh;
        font-weight: 700;
    }
    
    .enable-para {
        font-family: inherit;
        font-size: 2vh;
        font-weight: 300;
        max-width: 100vw;
        margin: 2vh 5vw;
        /* text-align: center; */
    }
    
    .product {
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 4vh 1vw;
    }
    
    
    .headings {
        max-width: 90vw;
        margin: 2vh 5vw;
    }
    
    .green-heading {
        font-family: inherit;
        font-size: 5vh;
        font-weight: 700;
        color: #00C680;
    }
    
    .normal-heading {
        font-family: inherit;
        font-size: 3vh;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 4vh;
        margin: 0;
        padding: 0;
    }
    
    .para {
        font-family: inherit;
        font-size: 3vh;
        font-weight: 300;
        margin: 5vh 13vw 0 0;
        text-align: left;
        line-height: 3vh;
    }
    
    .rectangle {
        max-width: 50vh;
        margin: 5vh 5vw;
        width: 250px;
        height: 180px;
        /* background-color: black; */
    }
    
    .rectangle > img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .big-green {
        width: 3vw;
        height: 3vw;
        background-color: #00C680;
        border-radius: 50%;
    }
}
