@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

.control-line {
    background-color: #00C680;
    max-width: 100vw;
    height: 80vh;
    margin-top: 7vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0vh 2vw;
}

.headings-div {
    max-width: 70vw;
    padding: 4vw;
}

.headings {
    font-family: inherit;
    font-size: 11vh;
    font-weight: 700;
    color: white;
}

.heading {
    font-family: inherit;
    font-size: 6vh;
    font-weight: 700;
    color: #373737;
    line-height: 8vh;
}

.circle {
    width: 30vw;
    height: 30vw;
    background-color: #000000;
    border-radius: 50%;
    margin: 5vw;
}

.circle > img {
    max-width: 100%;
    max-height: 100%;
}


@media (max-width: 767px) {

    .control-line {
        background-color: #00C680;
        max-width: 100vw;
        height: 60vh;
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4vh 2vw;
    }

    .headings-div {
        max-width: 80vw;
        padding: 3vh 1vw;
    }
    
    .headings {
        font-family: inherit;
        font-size: 5vh;
        font-weight: 700;
        color: white;
    }
    
    .heading {
        font-family: inherit;
        font-size: 3vh;
        font-weight: 700;
        color: #373737;
        line-height: 4vh;
    }

    .circle {
        width: 50vw;
        height: 50vw;
        background-color: #000000;
        border-radius: 50%;
        margin: 5vw;
    }

    .circle > img {
        max-width: 100%;
        max-height: 100%;
    }
}
