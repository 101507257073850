.contact {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #1C3C67;
    color: #FFFFFF;
    width: 100vw;
    height: 40vh;
}

.logo{
    background-color: white;
    border-radius: 12px;
    /* width: 5vw; */
}

.logo-div {
    text-align: center;
}

.links:hover {
    cursor: pointer;
}


@media (max-width: 767px) {
    .contact {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #1C3C67;
        color: #FFFFFF;
        width: 100vw;
        height: 60vh;
    }
}